/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react";

class StepOne extends React.Component {
  constructor(props) {
    super(props);
    this.nextButtonRef = React.createRef();
  }

  simulateNextClick = () => {
    setTimeout(() => {
      if (this.nextButtonRef.current) {
        this.nextButtonRef.current.click();
      } else {
      }
    }, 100);
  };

  render() {
    return (
      <div
        className="multisteps-form__panel js-active"
        data-animation="slideHorz"
      >
        <div className="wizard-forms">
          <div className="inner pb-100 clearfix">
            <div className="form-content pera-content">
              <div className="step-inner-content">
                <div className="row" style={{ marginLeft: "0" }}>
                  <span
                    className="step-box-icon "
                    style={{ marginBottom: "4rem" }}
                  >
                    <img
                      src={require("../../assets/img/logo_lumos.png").default}
                      alt=""
                    />
                  </span>
                </div>

                <span className="step-no bottom-line">Admissions</span>

                {this.props.applicationObj.IsInitialAttendance === false && (
                  <React.Fragment>
                    <h2>Are you applying for:</h2>

                    <div className="services-select-option4">
                      <ul>
                        <div
                          onClick={this.props.step1ApplicationChoiceRender.bind(
                            this,
                            2
                          )}
                        >
                          <li className="bg-white">
                            <label>
                              Transfer In
                              <input type="radio" name="choice" />
                            </label>
                          </li>
                        </div>

                        <div
                          onClick={this.props.step1ApplicationChoiceRender.bind(
                            this,
                            3
                          )}
                        >
                          <li className="bg-white">
                            <label>
                              Change of Status
                              <input type="radio" name="choice" />
                            </label>
                          </li>
                        </div>
                        <div
                          onClick={this.props.step1ApplicationChoiceRender.bind(
                            this,
                            4
                          )}
                        >
                          <li className="bg-white">
                            <label>
                              US Resident
                              <input type="radio" name="choice" />
                            </label>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </React.Fragment>
                )}

                <h2>
                  {this.props.applicationObj.IsInitialAttendance === true
                    ? "Application Code (optional)"
                    : "Discount Code (optional)"}
                </h2>
                <label className="label-input">
                  Please inform the code you received.
                </label>

                <div className="row form-inner-area">
                  <div className="col-md-3">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <input
                        type="text"
                        className="form-control"
                        name="FullName"
                        value={this.props.data.PartnerCode}
                        onChange={this.props.handlePartnerCodeInput.bind(this)}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <button
                        type="button"
                        className="btn btn-primary btn-xlarge"
                        onClick={this.props.validatePartnerCode.bind(this)}
                        disabled={
                          this.props.data.PartnerCode.length === 0 ||
                          this.props.data.ValidatingPartnerCode === true
                        }
                        style={{
                          cursor:
                            this.props.data.PartnerCode.length === 0
                              ? "auto"
                              : "pointer",
                        }}
                      >
                        Validate
                      </button>
                    </div>
                  </div>
                </div>

                {this.props.data.ErrorMsgPartner.length === 0 &&
                  this.props.data.FinalPartnerCode.length > 0 &&
                  ((this.props.data.PartnerDiscountPercentage > 0 && (
                    <label className="label-input" style={{ color: "#28a745" }}>
                      {" The code " +
                        this.props.data.FinalPartnerCode +
                        " has been successfully validated and the " +
                        this.props.data.PartnerDiscountPercentage +
                        "% discount will be applied before your payment."}
                    </label>
                  )) || (
                    <label className="label-input" style={{ color: "#28a745" }}>
                      {" The code " +
                        this.props.data.FinalPartnerCode +
                        " has been successfully validated."}
                    </label>
                  ))}

                {this.props.data.ErrorMsgPartner.length > 0 && (
                  <label className="label-input" style={{ color: "#dc3545" }}>
                    {this.props.data.ErrorMsgPartner}
                  </label>
                )}

                {this.props.data.ErrorMsgPartner.length === 0 &&
                  this.props.data.PartnerCode.length > 0 && (
                    <label className="label-input" style={{ color: "#007bff" }}>
                      Click on the 'Validate' button to proceed
                    </label>
                  )}

                {this.props.data.PartnerCodeIsCampaign == false && (
                  <React.Fragment>
                    <h2>Agent Code (optional)</h2>
                    <label className="label-input">
                      Please inform the referral code.
                    </label>

                    <div className="row form-inner-area">
                      <div className="col-md-3">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <input
                            type="text"
                            className="form-control"
                            name="FullName"
                            value={this.props.data.ReferralCode}
                            onChange={this.props.handleReferralCodeInput.bind(
                              this
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <button
                            type="button"
                            className="btn btn-primary btn-xlarge"
                            onClick={this.props.validateReferralCode.bind(this)}
                            disabled={
                              this.props.data.ReferralCode.length === 0 ||
                              this.props.data.ValidatingReferralCode === true
                            }
                            style={{
                              cursor:
                                this.props.data.ReferralCode.length === 0
                                  ? "auto"
                                  : "pointer",
                            }}
                          >
                            Validate
                          </button>
                        </div>
                      </div>
                    </div>

                    {this.props.data.ErrorMsgReferral.length === 0 &&
                      this.props.data.FinalReferralCode.length > 0 && (
                        <label
                          className="label-input"
                          style={{ color: "#28a745" }}
                        >
                          {" The code " +
                            this.props.data.FinalReferralCode +
                            " has been successfully validated."}
                        </label>
                      )}

                    {this.props.data.ErrorMsgReferral.length > 0 && (
                      <label
                        className="label-input"
                        style={{ color: "#dc3545" }}
                      >
                        {this.props.data.ErrorMsgReferral}
                      </label>
                    )}

                    {this.props.data.ErrorMsgReferral.length === 0 &&
                      this.props.data.ReferralCode.length > 0 && (
                        <label
                          className="label-input"
                          style={{ color: "#007bff" }}
                        >
                          Click on the 'Validate' button to proceed
                        </label>
                      )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          <div className="actions">
            <ul>
              <li className="disable" aria-disabled="true">
                <span className="js-btn-next" title="NEXT">
                  Backward <i className="fa fa-arrow-right" />
                </span>
              </li>
              <li>
                <span
                  ref={this.nextButtonRef}
                  className={`js-btn-next ${
                    this.formValid() ? "" : "button-disabled"
                  }`}
                  title="NEXT"
                >
                  NEXT <i className="fa fa-arrow-right" />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  formValid() {
    return (
      this.props.data.ApplicationChoiceId > -1 &&
      this.props.data.PartnerCode.length === 0 &&
      this.props.data.ReferralCode.length === 0
    );
  }
}

export default StepOne;
