import React from "react";
import Step1 from "./step/step-1";
import Step2 from "./step/step-2";
import Step3 from "./step/step-3";
import Step5 from "./step/step-5";
import Step6 from "./step/step-6";
import logo from "../assets/img/foto_apply.jpg";
import Countries from "../Countries.json";
import swal from "sweetalert";
import * as dateFns from "date-fns";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from "../assets/js/utils.js";

class VersionOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();

    this.ErrorMessage = this.ErrorMessage.bind(this);
    this.step1Ref = React.createRef();
  }

  getModalState = () => {
    return {
      Semesters: [],
      ApplicationObj: {
        IsInitialAttendance: IsInitialAttendance,
        Step1: {
          ApplicationChoiceId: IsInitialAttendance === true ? 1 : -1,
          Amount: 100,
          PartnerCode: "",
          FinalPartnerCode: "",
          PartnerDiscountPercentage: 0,
          PartnerCodeIsCampaign: false,
          ValidatingPartnerCode: false,
          ErrorMsgPartner: "",

          ReferralCode: "",
          FinalReferralCode: "",
          ValidatingReferralCode: false,
          ErrorMsgReferral: "",
        },
        Step2: {
          SemesterId: -1,
          CampusId: -1,
          PeriodId: -1,
          FileContent: null,
          FileName: "",
          AnotherDateStart: "",
          AnotherDateEnd: "",
        },
        Step3: {
          PersonalInformation: {
            FullName: "",
            Email: "",
            InvalidEmail: false,
            DateOfBirth: "",
            CountryOfBirth: "",
            CountryOfCitizenship: "",
            Gender: "",
            PhoneNumber: "",
            DateEntryUsa: "",
          },
          FileContent: null,
          FileName: "",
          AddressOutside: {
            Street: "",
            Street2: "",
            City: "",
            State: "",
            Zip: "",
            Country: "",
          },
          AddressInside: {
            Street: "",
            Street2: "",
            City: "",
            State: "",
            Zip: "",
            Country: "",
          },
          EmergencyContact: {
            FullName: "",
            Relationship: "",
            PhoneNumber: "",
            Street: "",
            Street2: "",
            City: "",
            State: "",
            Zip: "",
            Country: "",
          },
        },
        Step5: {
          Dependents: {
            HasDependents: "",
            NumberOfDependents: "",
            DependentList: [],
          },
          Transfer: {
            TransferSchool: "",
          },
          I20FileContent: null,
          I20FileName: "",
          TransferFileContent: null,
          TransferFileName: "",
        },
        Step6: {
          FileContent: null,
          FileName: "",
          trimmedDataURL: null,
          ModalIsOpen: false,
          ErrorSubmit: "",
          Agree1: false,
          Agree2: false,
          Agree3: false,
          Agree4: false,
          Agree5: false,
          Agree6: false,
          SubmittingApplication: false,

          ModalPaymentIsOpen: false,

          CardInformation: {
            number: "",
            expiry: "",
            cvc: "",
            name: "",
            focused: "",
          },

          PaymentError: "",
          IsPaying: false,

          AffidavitFileContent: null,
          AffidavitFileName: "",
        },
      },
    };
  };

  componentDidMount() {
    const { pathname } = window.location;

    this._refreshSemesters().then(() => {
      if (pathname.endsWith("/transfer")) {
        this._setApplicationChoice(2).then(() => {
          if (this.step1Ref.current) {
            this.step1Ref.current.simulateNextClick();
          }
        });
      }
    });
  }

  render() {
    return (
      <div className="wrapper">
        <div className="steps-area steps-area-fixed">
          <div className="image-holder">
            <img src={logo} alt="" />
          </div>
          <div className="steps clearfix">
            <ul className="tablist multisteps-form__progress">
              <li className="multisteps-form__progress-btn js-active current">
                <span>1</span>
              </li>
              <li className="multisteps-form__progress-btn">
                <span>2</span>
              </li>
              <li className="multisteps-form__progress-btn">
                <span>3</span>
              </li>
              {/* <li className="multisteps-form__progress-btn">
                <span>4</span>
              </li> */}
              <li className="multisteps-form__progress-btn">
                <span>4</span>
              </li>
              <li className="multisteps-form__progress-btn last">
                <span>5</span>
              </li>
            </ul>
          </div>
        </div>
        <form
          className="multisteps-form__form"
          action="#"
          id="wizard"
          method="POST"
        >
          <div className="form-area position-relative">
            <Step1
              data={this.state.ApplicationObj.Step1}
              step1ApplicationChoiceRender={this.step1ApplicationChoiceRender.bind(
                this
              )}
              applicationObj={this.state.ApplicationObj}
              validatePartnerCode={this.validatePartnerCode}
              validateReferralCode={this.validateReferralCode}
              handleUserInput={this.handleUserInput.bind(this)}
              handlePartnerCodeInput={this.handlePartnerCodeInput}
              handleReferralCodeInput={this.handleReferralCodeInput}
              ref={this.step1Ref}
            />
            <Step2
              data={this.state.ApplicationObj.Step2}
              step2SemesterRender={this.step2SemesterRender.bind(this)}
              step2CampusRender={this.step2CampusRender.bind(this)}
              step2PeriodRender={this.step2PeriodRender.bind(this)}
              onFileChange={this.onFileChange}
              applicationChoiceId={
                this.state.ApplicationObj.Step1.ApplicationChoiceId
              }
              Semesters={this.state.Semesters}
              handleUserInput={this.handleUserInput.bind(this)}
              handleAnotherDate={this.handleAnotherDate.bind(this)}
              partnerCodeIsCampaign={
                this.state.ApplicationObj.Step1.PartnerCodeIsCampaign
              }
            />
            <Step3
              data={this.state.ApplicationObj.Step3}
              handleSelectChange={this.handleSelectChange.bind(this)}
              handleUserInput={this.handleUserInput.bind(this)}
              Countries={Countries}
              onFileChange={this.onFileChange}
              applicationChoiceId={
                this.state.ApplicationObj.Step1.ApplicationChoiceId
              }
              emailOnBlur={this.emailOnBlur.bind(this)}
            />

            <Step5
              data={this.state.ApplicationObj.Step5}
              handleSelectChange={this.handleSelectChange.bind(this)}
              handleUserInput={this.handleUserInput.bind(this)}
              onFileChange={this.onFileChange}
              handleDependentChange={this.handleDependentChange.bind(this)}
              applicationChoiceId={
                this.state.ApplicationObj.Step1.ApplicationChoiceId
              }
              onFileChangeDependent={this.onFileChangeDependent}
              handleHasDependentSelectChange={
                this.handleHasDependentSelectChange
              }
            />
            <Step6
              data={this.state.ApplicationObj.Step6}
              step2SemesterRender={this.step2SemesterRender.bind(this)}
              onFileChange={this.onFileChange}
              sigPad={this.sigPad}
              clear={this.clear}
              trim={this.trim}
              toggleModal={this.toggleModal}
              getApplicationChoiceName={this.getApplicationChoiceName.bind(
                this
              )}
              getApplicationSemester={this.getApplicationSemester.bind(this)}
              getApplicationCampus={this.getApplicationCampus.bind(this)}
              getApplicationPeriod={this.getApplicationPeriod.bind(this)}
              handleSubmit={this.handleSubmit.bind(this)}
              ApplicationObj={this.state.ApplicationObj}
              handleCheckbox={this.handleCheckbox.bind(this)}
              applicationChoiceId={
                this.state.ApplicationObj.Step1.ApplicationChoiceId
              }
              toggleModalPayment={this.toggleModalPayment.bind(this)}
              pay={this.pay}
              handleUserInput={this.handleUserInput.bind(this)}
              handleCardInputChange={this.handleCardInputChange}
              handleInputFocus={this.handleInputFocus}
            />
          </div>
        </form>
      </div>
    );
  }

  step1ApplicationChoiceRender = (id) => {
    if (id === this.state.ApplicationObj.Step1.ApplicationChoiceId) return;

    let title = "";
    let message = "";

    switch (id) {
      case 1:
        title = "Initial Attendance";
        message =
          "Use this application if you are a new student and are applying for a Student Visa (F1) in a different country than the USA.";
        break;
      case 2:
        title = "Transfer In";
        message =
          "This application is for students transferring to Lumos from other schools.";
        break;
      case 3:
        title = "Change of Status";
        message =
          "This application is when you are in the United States and is changing the Visa Status.";
        break;
      case 4:
        title = "US Resident";
        message =
          "This application is only for Students Residents of the United States - Not for Student Visa.";
        break;
    }

    swal(title, message, "warning").then(() => {
      return new Promise((resolve, reject) => {
        this.setState((prevState) => {
          let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
          let Step1 = ApplicationObj.Step1;
          let Step6 = ApplicationObj.Step6;

          Step1.ApplicationChoiceId = id;

          Step6.Agree1 = false;
          Step6.Agree2 = false;
          Step6.Agree3 = false;
          Step6.Agree4 = false;
          Step6.Agree6 = false;

          return { ApplicationObj };
        }, resolve(true));
      });
    });
  };

  step2SemesterRender = (id) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step2 = ApplicationObj.Step2;

        Step2.SemesterId = id;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  step2CampusRender = (id) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step2 = ApplicationObj.Step2;

        Step2.CampusId = id;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  step2PeriodRender = (id) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step2 = ApplicationObj.Step2;

        Step2.PeriodId = id;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  onFileChange = (parent, fileContent, fileName, event) => {
    let file = event.target.files[0];

    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj[parent];

        if (file !== undefined) {
          let fsize = file.size;
          let total = Math.round(fsize / 1024);

          if (total > 20480) {
            swal(
              "File size error",
              "'" +
                file.name +
                "'" +
                " file size exceeds maximum limit. Maximum allowed file size is 20 MB.",
              "error"
            );
          } else {
            obj[fileContent] = file;
            obj[fileName] = file.name;
          }
        }

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handleUserInput = (step, parent, e) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj[step];

        obj[parent][e.target.name] = e.target.value;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handleSelectChange = (step, parent, name, event) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj[step];

        obj[parent][name] = event.target.value;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handleHasDependentSelectChange = (event) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step5;

        obj.Dependents.HasDependents = event.target.value;

        if (event.target.value !== "Y") {
          obj.Dependents.DependentList = [];
          obj.Dependents.NumberOfDependents = "";
        }

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handleDependentChange(index, e) {
    const { name, value } = e.target;

    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step5.Dependents.DependentList;

        if (obj.find((x) => x.index === index)) {
          obj[index][name] = value;
        } else {
          obj.push({ index: index, [name]: value });
        }

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  onFileChangeDependent = (index, fileContent, fileName, event) => {
    let file = event.target.files[0];

    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step5.Dependents.DependentList;

        if (file !== undefined) {
          let fsize = file.size;
          let total = Math.round(fsize / 1024);

          if (total > 20480) {
            swal(
              "File size error",
              "'" +
                file.name +
                "'" +
                " file size exceeds maximum limit. Maximum allowed file size is 20 MB.",
              "error"
            );
          } else {
            if (obj.find((x) => x.index === index)) {
              obj[index][fileContent] = file;
              obj[index][fileName] = file.name;
            } else {
              obj.push({
                index: index,
                [fileContent]: file,
                [fileName]: file.name,
              });
            }
          }
        }

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  clear = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        (prevState) => {
          let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
          let obj = ApplicationObj.Step6;

          obj.trimmedDataURL = null;

          return { ApplicationObj };
        },

        resolve(true)
      );
    });
  };

  trim = (canvas) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step6;

        obj.trimmedDataURL = canvas;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  getApplicationChoiceName() {
    let id = this.state.ApplicationObj.Step1.ApplicationChoiceId;

    return id === 1
      ? "Initial Attendance"
      : id === 2
      ? "Transfer In"
      : id === 3
      ? "Change of Status"
      : id === 4
      ? "NON-F1"
      : "";
  }

  getApplicationSemester() {
    let id = this.state.ApplicationObj.Step2.SemesterId;

    let semester = this.state.Semesters.find((x) => x.sequential == id);

    return semester && semester.name;
  }

  getApplicationCampus() {
    let id = this.state.ApplicationObj.Step2.CampusId;

    return id === 1 ? "Salt Lake City" : id === 2 ? "Orem" : "";
  }

  getApplicationPeriod() {
    let id = this.state.ApplicationObj.Step2.PeriodId;

    return id === 1
      ? "1 Academic Semester"
      : id === 2
      ? "1 Year"
      : id === 3
      ? this.state.ApplicationObj.Step2.AnotherDateStart +
        " - " +
        this.state.ApplicationObj.Step2.AnotherDateEnd
      : "";
  }

  isValidating(bool) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step1;

        obj.ValidatingPartnerCode = bool;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  isValidatingReferral(bool) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step1;

        obj.ValidatingReferralCode = bool;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  isSubmitting(bool) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step6;

        obj.SubmittingApplication = bool;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  isPaying(bool) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step6;

        obj.IsPaying = bool;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  setPaymentError(msg) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step6;

        obj.PaymentError = msg;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  setSubmitError(msg) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step6;

        obj.ErrorSubmit = msg;

        return { ApplicationObj };
      }, resolve(true));
    });
  }

  validatePartnerCode = () => {
    if (
      this.state.ApplicationObj.Step1.PartnerCode.length > 0 &&
      this.state.ApplicationObj.Step1.ValidatingPartnerCode === false
    ) {
      this.isValidating(true).then(() => {
        const self = this;

        const requestOptions = {
          crossDomain: true,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        const isDevelopment = false;
        const url = isDevelopment
          ? "http://localhost:58794"
          : "https://api.lumos.edu";

        return fetch(
          url +
            "/partners/validatecode?code=" +
            encodeURIComponent(self.state.ApplicationObj.Step1.PartnerCode),
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            return new Promise((resolve, reject) => {
              self.setState((prevState) => {
                let ApplicationObj = Object.assign(
                  {},
                  prevState.ApplicationObj
                );
                let obj = ApplicationObj.Step1;

                if (data.success === true) {
                  obj.PartnerDiscountPercentage = data.data.discountPercentage;
                  obj.FinalPartnerCode = data.data.code;
                  obj.PartnerCodeIsCampaign = data.data.isCampaign;
                  obj.PartnerCode = "";
                  obj.ErrorMsgPartner = "";

                  if (data.data.isCampaign) {
                    obj.ReferralCode = "";
                    obj.FinalReferralCode = "";
                    obj.ErrorMsgReferral = "";
                  }
                } else {
                  let message = "";
                  if (data.messages)
                    message = data.messages.map((error) => error).join("\n");

                  obj.PartnerCode = "";
                  obj.FinalPartnerCode = "";
                  obj.PartnerCodeIsCampaign = false;
                  obj.PartnerDiscountPercentage = 0;
                  obj.ErrorMsgPartner = message;
                }

                obj.ValidatingPartnerCode = false;

                return { ApplicationObj };
              }, resolve(true));
            });
          })
          .catch(function (error) {
            return new Promise((resolve, reject) => {
              self.setState((prevState) => {
                let ApplicationObj = Object.assign(
                  {},
                  prevState.ApplicationObj
                );
                let obj = ApplicationObj.Step1;

                obj.PartnerDiscountPercentage = 0;
                obj.ErrorMsgPartner = "Invalid code";
                obj.FinalPartnerCode = "";
                obj.PartnerCodeIsCampaign = false;
                obj.PartnerCode = "";
                obj.ValidatingPartnerCode = false;

                return { ApplicationObj };
              }, resolve(true));
            });
          });
      });
    }
  };

  validateReferralCode = () => {
    if (
      this.state.ApplicationObj.Step1.ReferralCode.length > 0 &&
      this.state.ApplicationObj.Step1.ValidatingReferralCode === false
    ) {
      this.isValidatingReferral(true).then(() => {
        const self = this;

        const requestOptions = {
          crossDomain: true,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        const isDevelopment = false;
        const url = isDevelopment
          ? "http://localhost:58794"
          : "https://api.lumos.edu";

        return fetch(
          url +
            "/students/ValidateReferralCode?code=" +
            encodeURIComponent(self.state.ApplicationObj.Step1.ReferralCode),
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            return new Promise((resolve, reject) => {
              self.setState((prevState) => {
                let ApplicationObj = Object.assign(
                  {},
                  prevState.ApplicationObj
                );
                let obj = ApplicationObj.Step1;

                if (data.success === true) {
                  obj.FinalReferralCode = data.data;
                  obj.ReferralCode = "";
                  obj.ErrorMsgReferral = "";
                } else {
                  let message = "";
                  if (data.messages)
                    message = data.messages.map((error) => error).join("\n");

                  obj.ReferralCode = "";
                  obj.FinalReferralCode = "";
                  obj.ErrorMsgReferral = message;
                }

                obj.ValidatingReferralCode = false;

                return { ApplicationObj };
              }, resolve(true));
            });
          })
          .catch(function (error) {
            return new Promise((resolve, reject) => {
              self.setState((prevState) => {
                let ApplicationObj = Object.assign(
                  {},
                  prevState.ApplicationObj
                );
                let obj = ApplicationObj.Step1;

                obj.ErrorMsgReferral = "Invalid code";
                obj.FinalReferralCode = "";
                obj.ReferralCode = "";
                obj.ValidatingReferralCode = false;

                return { ApplicationObj };
              }, resolve(true));
            });
          });
      });
    }
  };

  toggleModal = (e) => {
    e.preventDefault();

    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step6;

        obj.ErrorSubmit = "";
        obj.ModalIsOpen = !obj.ModalIsOpen;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  toggleModalPayment = (e) => {
    if (e) e.preventDefault();

    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step6;

        obj.ModalPaymentIsOpen = !obj.ModalPaymentIsOpen;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  }

  async fillFile(applyObj, name) {
    if (
      applyObj["File" + name] !== null &&
      applyObj["File" + name] !== undefined
    ) {
      let base64File = await this.getBase64(applyObj["File" + name]);
      let fileName = applyObj["File" + name].name;

      applyObj["Document" + name] = {
        FileContentBase64: base64File,
        FileName: fileName,
      };

      applyObj["File" + name] = null;
    }
  }

  async fillDependentsListBase64() {
    let dependents = [];
    this.state.ApplicationObj.Step5.Dependents.DependentList.map(
      (dependent, index) =>
        dependents.push({
          FullName: dependent.FullName,
          DateofBirth: dependent.DateofBirth,
          File: dependent.FileContent,
        })
    );

    await Promise.all(
      dependents.map(async (dependent) => {
        let base64File = await this.getBase64(dependent.File);
        let fileName = dependent.File.name;

        dependent.Document = {
          FileContentBase64: base64File,
          FileName: fileName,
        };
        dependent.File = null;
      })
    );

    return dependents;
  }

  finishSubmit(response) {
    if (this.state.ApplicationObj.Step6.SubmittingApplication === false) {
      this.isSubmitting(true).then(async () => {
        const self = this;

        let stateObj = Object.assign({}, this.state.ApplicationObj);

        let total = (
          stateObj.Step1.Amount -
          stateObj.Step1.Amount *
            (stateObj.Step1.PartnerDiscountPercentage / 100)
        ).toFixed(2);

        let applyObj = {
          ApplicationChoice: this.getApplicationChoiceName(
            stateObj.Step1.ApplicationChoiceId
          ),
          PartnerCode: stateObj.Step1.FinalPartnerCode,
          PartnerCodeIsCampaign: stateObj.Step1.PartnerCodeIsCampaign,
          ReferralCode: stateObj.Step1.FinalReferralCode,
          PartnerDiscountPercentage: stateObj.Step1.PartnerDiscountPercentage,
          Amount: total,

          Semester: this.getApplicationSemester(),
          Campus: this.getApplicationCampus(),
          Period: this.getApplicationPeriod(),

          PersonalFullName: stateObj.Step3.PersonalInformation.FullName,
          PersonalEmail: stateObj.Step3.PersonalInformation.Email,
          PersonalDateOfBirth: stateObj.Step3.PersonalInformation.DateOfBirth,
          PersonalCountryOfBirth:
            stateObj.Step3.PersonalInformation.CountryOfBirth,
          PersonalCountryOfCitizenship:
            stateObj.Step3.PersonalInformation.CountryOfCitizenship,
          PersonalGender: stateObj.Step3.PersonalInformation.Gender,
          PersonalPhoneNumber: stateObj.Step3.PersonalInformation.PhoneNumber,
          DateEntryUsa: stateObj.Step3.PersonalInformation.DateEntryUsa,

          AddressOutStreet: stateObj.Step3.AddressOutside.Street,
          AddressOutStreet2: stateObj.Step3.AddressOutside.Street2,
          AddressOutCity: stateObj.Step3.AddressOutside.City,
          AddressOutState: stateObj.Step3.AddressOutside.State,
          AddressOutZip: stateObj.Step3.AddressOutside.Zip,
          AddressOutCountry: stateObj.Step3.AddressOutside.Country,

          AddressInStreet: stateObj.Step3.AddressInside.Street,
          AddressInStreet2: stateObj.Step3.AddressInside.Street2,
          AddressInCity: stateObj.Step3.AddressInside.City,
          AddressInState: stateObj.Step3.AddressInside.State,
          AddressInZip: stateObj.Step3.AddressInside.Zip,
          AddressInCountry: stateObj.Step3.AddressInside.Country,

          EmergencyFullName: stateObj.Step3.EmergencyContact.FullName,
          EmergencyRelationship: stateObj.Step3.EmergencyContact.Relationship,
          EmergencyPhoneNumber: stateObj.Step3.EmergencyContact.PhoneNumber,
          EmergencyStreet: stateObj.Step3.EmergencyContact.Street,
          EmergencyStreet2: stateObj.Step3.EmergencyContact.Street2,
          EmergencyCity: stateObj.Step3.EmergencyContact.City,
          EmergencyState: stateObj.Step3.EmergencyContact.State,
          EmergencyZip: stateObj.Step3.EmergencyContact.Zip,
          EmergencyCountry: stateObj.Step3.EmergencyContact.Country,

          TotalDependents: stateObj.Step5.Dependents.NumberOfDependents,
          TransferSchool: stateObj.Step5.Transfer.TransferSchool,

          PaymentId: response,
          DependentList: [],

          FileVisa: this.state.ApplicationObj.Step2.FileContent,
          FilePassport: this.state.ApplicationObj.Step3.FileContent,
          FileI20: this.state.ApplicationObj.Step5.I20FileContent,
          FileTransfer: this.state.ApplicationObj.Step5.TransferFileContent,
          FileBank: this.state.ApplicationObj.Step6.FileContent,
          FileAffidavit: this.state.ApplicationObj.Step6.AffidavitFileContent,

          DocumentSignature: {
            FileContentBase64: this.state.ApplicationObj.Step6.trimmedDataURL,
            FileName: "Signature.jpg",
          },
        };

        const isDevelopment = false;
        const url = isDevelopment
          ? "http://localhost:58794"
          : "https://api.lumos.edu";

        await this.fillDependentsListBase64().then((response) => {
          response.forEach((element) => {
            applyObj.DependentList.push(element);
          });

          this.fillFile(applyObj, "Visa").then(() => {
            this.fillFile(applyObj, "Passport").then(() => {
              this.fillFile(applyObj, "I20").then(() => {
                this.fillFile(applyObj, "Transfer").then(() => {
                  this.fillFile(applyObj, "Bank").then(() => {
                    this.fillFile(applyObj, "Affidavit").then(() => {
                      const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(applyObj),
                      };

                      return fetch(url + "/apply", requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                          self.isSubmitting(false).then(() => {
                            if (data.success === true) {
                              alert(
                                "Thank you for submitting your application."
                              );
                              window.location.assign("https://lumos.edu");
                            } else {
                              let message = data.messages
                                ? data.messages.map((error) => error).join("\n")
                                : "An internal server error has occurred.";

                              self.setSubmitError(message);
                            }
                          });
                        })
                        .catch(function (error) {
                          self.isSubmitting(false).then(() => {
                            let message =
                              "A server connection error occurred in your application. Error message: " +
                              self.ErrorMessage(error);

                            self.setSubmitError(message);
                          });
                        });
                    });
                  });
                });
              });
            });
          });
        });
      });
    }
  }

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  handleSubmit(event) {
    event.preventDefault();

    const self = this;

    let total = (
      self.state.ApplicationObj.Step1.Amount -
      self.state.ApplicationObj.Step1.Amount *
        (self.state.ApplicationObj.Step1.PartnerDiscountPercentage / 100)
    ).toFixed(2);

    if (total > 0) self.toggleModalPayment();
    else self.finishSubmit(null);
  }

  handlePartnerCodeInput = (e) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step1;

        obj.PartnerCode = e.target.value;
        obj.ErrorMsgPartner = "";

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handleAnotherDate = (e) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step2;

        obj[e.target.name] = e.target.value;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handleReferralCodeInput = (e) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let obj = ApplicationObj.Step1;

        obj.ReferralCode = e.target.value;
        obj.ErrorMsgReferral = "";

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handleCheckbox = (index, e) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step6 = ApplicationObj.Step6;

        Step6["Agree" + index] = !Step6["Agree" + index];

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  ErrorMessage(error) {
    var message = "";

    if (error.response) {
      if (error.response.data) {
        if (error.response.data.messages) {
          message = error.response.data.messages
            .map((error) => error)
            .join("\n");
        } else {
          message = error.response.data;
        }
      } else {
        message = error.message;
      }
    } else if (error.message) {
      message = error.message;
    }

    return message;
  }

  emailIsValid(email) {
    return (
      email.length > 0 && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    );
  }

  emailOnBlur = async (e) => {
    const value = e.target.value;

    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step3 = ApplicationObj.Step3;

        Step3.PersonalInformation.InvalidEmail = !this.emailIsValid(value);

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  pay = async (e) => {
    e.preventDefault();

    if (this.state.ApplicationObj.Step6.IsPaying === false) {
      this.isPaying(true).then(async () => {
        let self = this;
        let stateObj = Object.assign({}, this.state.ApplicationObj);

        let total = (
          stateObj.Step1.Amount -
          stateObj.Step1.Amount *
            (stateObj.Step1.PartnerDiscountPercentage / 100)
        ).toFixed(2);

        let applyObj = {
          CardName: this.state.ApplicationObj.Step6.CardInformation.name,
          CardNumber: this.state.ApplicationObj.Step6.CardInformation.number,
          CardExpMonth:
            this.state.ApplicationObj.Step6.CardInformation.expiry.substring(
              0,
              2
            ),
          CardExpYear:
            this.state.ApplicationObj.Step6.CardInformation.expiry.substring(
              3,
              5
            ),
          CardCVC: this.state.ApplicationObj.Step6.CardInformation.cvc,
          Amount: total,
        };

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(applyObj),
        };

        const isDevelopment = false;
        const url = isDevelopment
          ? "http://localhost:58794"
          : "https://api.lumos.edu";

        return fetch(url + "/apply/GetPaymentId", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            self.isPaying(false).then(() => {
              if (data.success === true) {
                self.toggleModalPayment().then(() => {
                  self.finishSubmit(data.data);
                });
              } else {
                let message = data.messages
                  ? data.messages.map((error) => error).join("\n")
                  : "An internal server error has occurred.";

                self.setPaymentError(message);
              }
            });
          })
          .catch(function (error) {
            self.isPaying(false).then(() => {
              let message =
                "A server connection error occurred in your application. Error message: " +
                self.ErrorMessage(error);

              self.setPaymentError(message);
            });
          });
      });
    }
  };

  handleCardInputChange = (e) => {
    const { name, value } = e.target;

    let valueResult = value;

    return new Promise((resolve, reject) => {
      if (name === "number") {
        valueResult = formatCreditCardNumber(value);
      } else if (name === "expiry") {
        valueResult = formatExpirationDate(value);
      } else if (name === "cvc") {
        valueResult = formatCVC(value);
      }

      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step6 = ApplicationObj.Step6;

        Step6.CardInformation[name] = valueResult;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  handleInputFocus = ({ target }) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);
        let Step6 = ApplicationObj.Step6;

        Step6.CardInformation.focused = target.name;

        return { ApplicationObj };
      }, resolve(true));
    });
  };

  _refreshSemesters = () => {
    const requestOptions = {
      crossDomain: true,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const isDevelopment = false;
    const url = isDevelopment
      ? "http://localhost:58794"
      : "https://api.lumos.edu";

    return fetch(url + "/semesters/?page=1&pageSize=999999", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        return new Promise((resolve, reject) => {
          let semesters = [];

          data.data.items
            .filter(
              (x) =>
                dateFns.parseISO(x.endDate) >= new Date() &&
                (this.state.ApplicationObj.IsInitialAttendance === false ||
                  (this.state.ApplicationObj.IsInitialAttendance === true &&
                    dateFns.differenceInDays(
                      dateFns.parseISO(x.startDate),
                      new Date()
                    ) >= 10))
            )
            .sort(function (a, b) {
              return new Date(a.startDate) - new Date(b.startDate);
            })
            .map((sem, index) =>
              semesters.push({
                id: sem.id,
                name: sem.name,
                startDate: dateFns.parseISO(sem.startDate),
                endDate: dateFns.parseISO(sem.endDate),
                sequential: index,
              })
            );

          this.setState({ Semesters: semesters }, resolve(true));
        });
      });
  };

  _setApplicationChoice = (id) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let ApplicationObj = Object.assign({}, prevState.ApplicationObj);

        let Step1 = ApplicationObj.Step1;
        let Step6 = ApplicationObj.Step6;

        Step1.ApplicationChoiceId = id;

        Step6.Agree1 = false;
        Step6.Agree2 = false;
        Step6.Agree3 = false;
        Step6.Agree4 = false;
        Step6.Agree6 = false;

        return { ApplicationObj };
      }, resolve(true));
    });
  };
}

const IsInitialAttendance = false;

export default VersionOne;
